import React, { useState } from 'react'
import './boxStyle.css'
import QuestionRenderer from './QuestionRenderer'

function SubmissionForm2 ({
  brandLogo,
  brandName,
  taskTitle,
  subtaskInfos,
  onNavItemClick,
  onAnswerChange,
  handleSubmit
}) {
  const [selectedSubtaskId, setSelectedSubtaskId] = useState(null)
  const [answers, setAnswers] = useState({})

  const handleItemClick = subtask => {
    onNavItemClick(subtask.subtaskId)
    setSelectedSubtaskId(subtask.subtaskId)
  }

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [selectedSubtaskId]: {
        ...(prevAnswers[selectedSubtaskId] || {}),
        [questionIndex]: answer
      }
    }))

    onAnswerChange(selectedSubtaskId, questionIndex, answer)
  }

  const handleCancelClick = () => {
    setSelectedSubtaskId(null)
    setAnswers({})
    onAnswerChange(null, null, null)
  }

  const handleSubmitClick = () => {
    handleSubmit()
  }

  const selectedSubtask = subtaskInfos.find(
    subtask => subtask.subtaskId === selectedSubtaskId
  )
  const questions = selectedSubtask
    ? selectedSubtask.subtask_submission_form.submissionForm
    : []

  return (
    <div className='w-full mx-auto p-4'>
      {!selectedSubtaskId && (
        <div className=' relative p-4 lg:p-10 border-2 border-boomcolor/30 shadow bg-gray-100/50 flex flex-col gap-2 items-start  rounded-xl w-full max-w-lg mx-auto'>
          <div className='max-auto text-center w-full space-y-2'>
            <div className='w-full h-full overflow-hidden mx-auto'>
              <img
                src={brandLogo}
                className='w-56 h-36 object-cover mx-auto'
                alt='logo'
              />
            </div>
            <h1 className='text-2xl lg:text-3xl font-medium text-center mx-auto text-gray-700 '>
              {taskTitle}
            </h1>
            <h1 className='text-xl text-gray-700 font-medium'>
              By{' '}
              <span className='text-boomcolor font-semibold'>{brandName}</span>
            </h1>
          </div>

          <h2 className='text-lg  font-semibold uppercase text-gray-600 text-left mt-4'>
            Select Subtask
          </h2>

          <div className='flex flex-col w-full items-center  gap-4 '>
            {subtaskInfos.map((subtask, index) => (
              <button
                key={subtask.subtaskId}
                onClick={() => handleItemClick(subtask)}
                className='w-full px-5 py-2 lg:py-3 text-lg lg:text-xl rounded-lg bg-boomcolor text-white hover:scale-105 duration-300 ease-in-out uppercase'
              >
                {subtask.subtaskTitle}
              </button>
            ))}
          </div>
        </div>
      )}
      {selectedSubtaskId && (
        <div className='max-w-screen-xl flex flex-col gap-5 mx-auto justify-center w-full items-center '>
          {questions.map((question, index) => (
            <div key={question.questionIndex} className='w-full  md:w-[600px]'>
              <QuestionRenderer
                key={question.questionIndex}
                question={question}
                answer={
                  (answers[selectedSubtaskId] &&
                    answers[selectedSubtaskId][question.questionIndex]) ||
                  ''
                }
                onAnswerChange={answer =>
                  handleAnswerChange(question.questionIndex, answer)
                }
              />
            </div>
          ))}
          <div>
            <button
              className='border-2 me-4 border-boomcolor rounded-md py-1 px-4 hover:bg-boomcolor hover:text-white'
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              className='border-2 border-boomcolor rounded-md py-1 px-4 hover:bg-boomcolor hover:text-white'
              //   endIcon={<SendIcon />}
              onClick={handleSubmitClick}
            >
              Submit Form
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubmissionForm2
