import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import photo from '../assets/Logo.png'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 240

function SideNavbar ({ taskType, subtaskInfos, onNavItemClick, window, code }) {
  // const { window } = props;
  // const subtask_infos = props.subtaskInfos;
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const navigate = useNavigate()

  const handleItemClick = subtask => {
    if (typeof onNavItemClick === 'function') {
      onNavItemClick(subtask.subtaskId)
    }
    navigate(`/${code}/${subtask.subtaskTitle.toLowerCase()}`)
  }

  const handlePersonalDetailClick = () => {
    navigate(`/${code}/personal-detail`)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        <img src={photo} alt='logo' />
      </Typography>
      <Divider />
      <div className='flex flex-col gap-1 mt-5 place-items-start '>
        {taskType === 1 ? (
          <>
            <button
              className='border-boomcolor text-lg  font-medium hover:text-boomcolor focus:bg-pink-100 px-8 p-2 rounded-md'
              onClick={handlePersonalDetailClick}
            >
              Personal Detail
            </button>
            {subtaskInfos.map(subtask => (
              <div
                key={subtask.subtaskId}
                onClick={() => handleItemClick(subtask)}
              >
                <button className='border-boomcolor text-lg  font-medium  hover:text-boomcolor focus:bg-pink-100 px-8 p-2 rounded-md'>{subtask.subtaskTitle} </button>
              </div>
            )) || <div>Loading...</div>}
          </>
        ) : null}
      </div>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component='nav'
        sx={{
          color: 'black',
          bgcolor: 'white',
         
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            {taskType === 1 && <MenuIcon />}
            <img src={photo} alt='logo' style={{ marginLeft: '10px' }} />
          </IconButton>
          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <img src={photo} alt='logo' />
          </Typography>
          <div className='  gap-3 justify-center hidden md:flex items-center'>
            {taskType === 1 ? (
              <>
                <button
                className='border-2 border-boomcolor rounded-md py-1 px-4 hover:bg-boomcolor hover:text-white uppercase text-boomcolor'
                  onClick={handlePersonalDetailClick}
                >
                  Personal Details
                </button>
                {subtaskInfos.map(subtask => (
                  <button
                     className='border-2 border-boomcolor rounded-md py-1 px-4 hover:bg-boomcolor hover:text-white  uppercase text-boomcolor'
                    key={subtask.subtaskId}
                    onClick={() => handleItemClick(subtask)}
                   
                  >
                    {subtask.subtaskTitle}
                  </button>
                )) || <div>Loading...</div>}
              </>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
      {taskType === 1 && (
        <nav>
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth
              }
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      )}
    </Box>
  )
}

export default SideNavbar
