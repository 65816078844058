import React from 'react'
import './boxStyle.css'
import { TextField } from '@mui/material'

function TimeQuestion ({ question, answer, onAnswerChange }) {
  const handleInputChange = event => {
    onAnswerChange(event.target.value)
  }

  return (
    <div className='bg-white shadow-lg p-4 rounded-md flex flex-col gap-2.5 md:gap-5'>
      <h3 className='font-bold text-lg'>
        <span className='p-1.5 px-3 text-base rounded-md bg-boomcolor text-white me-2'>
          {question.questionIndex}
        </span>
        {question.questionText}
      </h3>
      <h2>
        {question.questionText}
        {question.mandatory && <span className='text-red-500'> *</span>}
      </h2>
      <label>{question.question}</label>
      <input
        type='time'
        placeholder={question.question}
        value={answer}
        onChange={handleInputChange}
        className='bg-white rounded-full border-2 p-2.5 border-gray-300'
      />
    </div>
  )
}

export default TimeQuestion
