
import React from 'react'

function ShortAnswer ({ question, answer, onAnswerChange }) {
  const handleInputChange = event => {
    onAnswerChange(event.target.value)
  }
  return (
    <>
      <div className=' flex bg-white flex-col gap-2.5 md:gap-5 p-5 shadow-lg rounded-lg '>
      <h3 className='font-bold text-lg'><span className='p-1.5 px-3 text-base rounded-md bg-boomcolor text-white me-2'>{question.questionIndex}</span>{question.questionText} </h3>
        <h2>
          {question.questionText}
          {question.mandatory && <span className='text-red-500'> *</span>}
        </h2>
        <input
          label='Answer'
          value={answer}
          onChange={handleInputChange}
          type='text'
          placeholder='Type here'
          className='input w-full  rounded-full input-bordered  bg-white focus:border-boomcolor'
        />
      </div>
    </>
  )
}

export default ShortAnswer
