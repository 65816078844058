import React, { useRef, useState } from 'react'
import { RiAttachment2 } from 'react-icons/ri'
import imageCompression from 'browser-image-compression'
import { ClipLoader } from 'react-spinners'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'

function FileUploadQuestion ({ question, answer, onAnswerChange }) {
  const [fileName, setFileName] = useState('Choose File')
  const [isLoading, setIsLoading] = useState(false)
  const [resultUrl, setResultUrl] = useState('')

  // const ffmpeg = new FFmpeg({ log: true });
  const ffmpegRef = useRef(new FFmpeg())
  const messageRef = useRef(null)

  async function handleFileChange (event) {
    const file = event.target.files[0]
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
    const ffmpeg = ffmpegRef.current
    ffmpeg.on('log', ({ message }) => {
      // messageRef.current.innerHTML = message;
      console.log(message)
    })
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        'application/wasm'
      )
    })
    setFileName(event.target.files[0] ? event.target.files[0].name : '')

    setIsLoading(true)

    try {
      if (file.type.startsWith('image/')) {
        // Image compression
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }

        const compressedFile = await imageCompression(file, options)
        const reader = new FileReader()

        reader.onloadend = () => {
          onAnswerChange(reader.result)
          setIsLoading(false)
        }

        reader.onerror = () => {
          alert('FileReader error')
          setIsLoading(false)
        }

        reader.readAsDataURL(compressedFile)
        const compressedFileSize = compressedFile.size / 1024
        console.log(
          `The compressed file size is ${compressedFileSize.toFixed(2)} KB`
        )
      } else if (file.type.startsWith('video/')) {
        // Video compression
        const ffmpeg = ffmpegRef.current
        await ffmpeg.writeFile('input.mp4', await fetchFile(file))
        await ffmpeg.exec([
          '-i',
          'input.mp4',
          '-vf',
          'scale=-1:480',
          '-preset',
          'ultrafast',
          'output.mp4'
        ])
        const data = await ffmpeg.readFile('output.mp4')
        const url = URL.createObjectURL(
          new Blob([data.buffer], { type: 'video/mp4' })
        )
        setResultUrl(url)
        onAnswerChange(url)
        console.log(url)
        const compressedVideoSize = data.buffer.byteLength / 1024
        console.log(
          `The compressed video size is ${compressedVideoSize.toFixed(2)} KB`
        )
      }
    } catch (error) {
      console.error('Error during ffmpeg execution:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div key={question.questionIndex} className=' bg-white shadow-lg p-4 rounded-md flex flex-col gap-2.5 md:gap-5'>
      <h3 className='font-bold text-lg'>
        <span className='p-1.5 px-3 text-base rounded-md bg-boomcolor text-white me-2'>
          {question.questionIndex}
        </span>
        {question.questionText}
      </h3>
      <h2>
        {question.questionText}
        {question.mandatory && <span className='text-red-500'> *</span>}
      </h2>
      <div className='text-[14px] font-medium'>
        <div
          key={question.questionIndex}
          className='relative border  border-gray-300 rounded-full bg-white p-2.5 flex items-center justify-between cursor-pointer'
          onClick={() => document.getElementById(`uploadfile-${question.questionIndex}`).click()}
        >
          <label htmlFor='uploadfile'></label>
          <input
            id={`uploadfile-${question.questionIndex}`}
            name='uploadfile'
            type='file'
            className='hidden w-full cursor-pointer'
            onChange={handleFileChange}
          />
          <span id='file-name w-full' className='text-gray-600'>
            {fileName}
          </span>
          <RiAttachment2 className='text-2xl text-stone-700' />
        </div>
      </div>

      {/* <p ref={messageRef}></p> */}
      {isLoading ? <ClipLoader /> : null}
      {/* {resultUrl && (
                <article>
                    <header>
                        <h3 style={{ margin: 0 }}>Output</h3>
                    </header>
                    <a href={resultUrl} download>
                        Download
                    </a>
                    <br />
                    <video src={resultUrl} controls style={{ width: 480, height: 320 }} />
                </article>
            )} */}
    </div>
  )
}

export default FileUploadQuestion
